// extracted by mini-css-extract-plugin
export var blockDescription = "payload-module--block-description--f8186";
export var blocksContainer = "payload-module--blocks-container--43761";
export var blocksInRowsWithOneActiveInnerWrapper = "payload-module--blocks-in-rows-with-one-active-inner-wrapper--10167";
export var blocksInRowsWithOneActiveInnerWrapperTwo = "payload-module--blocks-in-rows-with-one-active-inner-wrapper-two--eac33";
export var blocksInRowsWithOneActiveSection = "payload-module--blocks-in-rows-with-one-active-section--dd384";
export var blocksInRowsWithOneActiveSectionTwo = "payload-module--blocks-in-rows-with-one-active-section-two--6c009";
export var blocksRedirectingToResourcesInnerWrapper = "payload-module--blocks-redirecting-to-resources-inner-wrapper--98a30";
export var blocksRedirectingToResourcesSubtitle = "payload-module--blocks-redirecting-to-resources-subtitle--10c13";
export var blocksRedirectingToResourcesTitlesContainer = "payload-module--blocks-redirecting-to-resources-titles-container--b3078";
export var coloredBlocksContainer = "payload-module--colored-blocks-container--5da93";
export var coloredBlocksInnerWrapper = "payload-module--colored-blocks-inner-wrapper--c2325";
export var coloredBlocksRightPart = "payload-module--colored-blocks-right-part--2517c";
export var commonOuterWrapper = "payload-module--common-outer-wrapper--80c5c";
export var companyInNumbersSection = "payload-module--company-in-numbers-section--37eef";
export var companyInNumbersTitlesContainer = "payload-module--company-in-numbers-titles-container--be00b";
export var contactButtonTextSection = "payload-module--contact-button-text-section--3b5d0";
export var contactButtonTextSectionContainer = "payload-module--contact-button-text-section-container--31d14";
export var contactButtonTextSectionInnerWrapper = "payload-module--contact-button-text-section-inner-wrapper--753b7";
export var headerBorder = "payload-module--header-border--84ab9";
export var headerInnerWrapper = "payload-module--header-inner-wrapper--a2ded";
export var headerTitle = "payload-module--header-title--fb3aa";
export var headerTitleBorderWidth = "payload-module--header-title-border-width--bc8f0";
export var imageContainer = "payload-module--image-container--dd0b1";
export var innerWrapperTestimonials = "payload-module--inner-wrapper-testimonials--c68b3";
export var opacitySliderTitlesContainer = "payload-module--opacity-slider-titles-container--70fc8";
export var opacitySliderVisibleText = "payload-module--opacity-slider-visible-text--55feb";
export var sectionUnderHeaderButton = "payload-module--section-under-header-button--c6c7e";
export var sectionUnderHeaderContentWrapper = "payload-module--section-under-header-content-wrapper--2f392";
export var sectionUnderHeaderInnerWrapper = "payload-module--section-under-header-inner-wrapper--809cd";
export var sectionUnderHeaderSliderInnerWrapper = "payload-module--section-under-header-slider-inner-wrapper--f9352";
export var sectionUnderHeaderText = "payload-module--section-under-header-text--0a6e7";
export var sectionUnderHeaderToggleVisibilityButton = "payload-module--section-under-header-toggle-visibility-button--7ff30";
export var singleBlockContainer = "payload-module--single-block-container--853f9";
export var sliderWithClickableBlocksInnerWrapper = "payload-module--slider-with-clickable-blocks-inner-wrapper--19d4d";
export var sliderWithClickableBlocksSection = "payload-module--slider-with-clickable-blocks-section--9f82f";
export var sliderWithClickableBlocksTitlesContainer = "payload-module--slider-with-clickable-blocks-titles-container--6930c";
export var techStackContainerWithTwoColumns = "payload-module--tech-stack-container-with-two-columns--f02e1";
export var techStackIconsForCategory = "payload-module--tech-stack-icons-for-category--b05e4";
export var techStackInnerWrapper = "payload-module--tech-stack-inner-wrapper--ed46e";
export var techStackRectangle = "payload-module--tech-stack-rectangle--303e1";
export var techStackSingleCategory = "payload-module--tech-stack-single-category--85dce";
export var techStackSingleColumn = "payload-module--tech-stack-single-column--a6f0c";
export var techStackSubtitle = "payload-module--tech-stack-subtitle--fe0f8";
export var titlesContainer = "payload-module--titles-container--4083b";